.NavBar {
	display: none;
}

.Layout {
	display: grid;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	margin: 0;
	grid-gap: 0;
	grid-template-areas: 'logo header' ' page page' 'navbar navbar';
	grid-template-rows: 54px auto max-content;
	grid-template-columns: 80px auto;
}

.MainLogo {
	display: none;
}

.Logo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 101%;
	background-color: var(--color-dark-grey);
}

.Page {
	box-sizing: border-box;
	background-color: var(--color-white100);
	grid-area: page;
	overflow-x: hidden;
}

.with-player {
	margin: 0 0 55px 0;
}

.Menu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
}

.Form {
	width: 100%;
	margin-top: 40px;
	margin-bottom: 90px;
	padding-right: 20px;
	padding-left: 20px;
}

.TwoColsContainer,
.TwoColsRow,
.TwoColsLeft,
.TwoColsRight {
	height: 100%;
}

@media screen and (min-width: 768px) {
	.Layout {
		grid-template-rows: 64px auto;
		grid-template-columns: 140px auto;
	}

	.Logo {
		width: 101%;
	}

	.Logo img {
		width: 65px;
	}

	.NavBar {
		display: block;
	}
}

@media screen and (min-width: 768px) {
	.Layout {
		grid-template-rows: 64px auto max-content;
	}
}

@media screen and (min-width: 1024px) {
	.MainLogo {
		display: flex;
	}

	.Layout {
    width: 100vw;
		height: 100vh;
		grid-template-areas: 'logo header' 'navbar page';
		grid-template-columns: 162px auto;
	}

	.Page {
		max-height: 100vh;
	}
}

@media screen and (min-width: 3840px) {
	.Layout {
		grid-template-rows: 162px auto;
		grid-template-columns: 324px auto;
	}
}
