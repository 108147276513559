.wrapperOutletTabs {
  display: flex;
  background-color: var(--color-light-grey);
}

.outletTab {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 30px 13px;
  font: var(--gilroy-Bold-16-19);
  color: var(--color-dark-grey);
  cursor: pointer;
}

.outletImg {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
}

.active {
  background-color: var(--color-white100);
  text-decoration: line-through;
}