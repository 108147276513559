.Wizard {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 48px;
	user-select: none;
}

.WizardStep,
.WizardStepClickable {
	font: var(--gilroy-Bold-16-19);
	width: 100%;
	margin: 10px;
	padding: 10px;
	user-select: none;
	text-align: center;
}

.WizardStep:global.active {
	text-decoration: line-through;
}

.WizardStep:global.disabled {
	cursor: not-allowed;
}

.WizardStep:global.complete {
	color: var(--color-light-green);
}

.WizardStep:global.default {
	color: var(--color-grey);
}

.WizardStepClickable:global.active {
	text-decoration: line-through;
	text-decoration-thickness: from-font;
}

.WizardStepClickable:global.disabled {
	cursor: not-allowed;
}

.WizardStepClickable:global.complete {
	color: var(--color-light-green);
	cursor: pointer;
}

.WizardStepClickable:global.default {
	color: var(--color-grey);
}
