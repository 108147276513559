.modal {
	width: 0;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 140000000;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	overflow: hidden !important;
	overflow-y: hidden !important;
	opacity: 0;
}

.modal.active {
	width: 100%;
	visibility: visible;
	opacity: 1;
	background: rgba(0, 0, 0, .5);
}

.container {
	position: relative;
	width: 416px;
	min-height: 600px;
	height: 100vh;
	background-color: var(--color-white);
	overflow: hidden !important;
	overflow-y: hidden !important;
	color: var(--color-dark-grey);
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.modalFooter__title,
.modalHeader__title {
	font: var(--gilroy-Medium-24);
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 1px;
	text-transform: lowercase;
}

.modal__icon {
	margin-right: 16px;
}

.modalHeader {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}

.modalHeader__titleWrapper {
	display: flex;
	align-items: center;
}


.modalHeader__icon {
	margin: 0 0 0 auto;
	font-size: 24px;
}

.modalHeader svg {}

.modalBody {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.modalBody * {
	letter-spacing: 1px;
}

.button__select {
	margin-top: auto;
	text-transform: lowercase;
}

.button__select:hover {
	background: #e5e5e5;
	border: 2px #ffffff solid;
}

.checkbox + .checkbox {
	margin-top: 12px;
}

.placeholder {
	margin: auto 0;
	font-size: 18px;
	color: var(--color-grey);
	text-align: center;
	font-weight: 600;
}