.mainWrapper {
	position: relative;
	width: 100%;
	font: var(--gilroy-Medium-18);
	text-align: left;
}

.inputHours,
.inputMinutes {
	border: none;
	max-width: 30px;
	text-align: left;
	padding: 0 !important;
}

.inputHours {
	text-align: right;
}

/* Chrome, Safari, Edge, Opera */
.inputHours::-webkit-outer-spin-button,
.inputHours::-webkit-inner-spin-button,
.inputMinutes::-webkit-outer-spin-button,
.inputMinutes::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.inputHours[type='number'],
.inputMinutes[type='number'] {
	-moz-appearance: textfield;
}

.inputWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 0px;
	border-bottom: 2px var(--color-grey) solid;
}

.inputBorderFocus {
	border-bottom: 2px var(--color-dark-grey100) solid;
}

.inputWrapperFocus {
	border-bottom: 2px var(--color-dark-grey100) solid;
}

.twentyfourClock {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.twentyfourClock img {
	margin: 0px 8px;
}

.inputsWrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.dropdownWrapper {
	position: absolute;
	top: 43px;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 238px;
	background-color: var(--color-light-grey);
}

.dropRightSide {
	width: 64px;
	height: 100%;
	background-color: var(--color-dark-grey100);
}

.active {
	color: var(--color-black);
	background-color: var(--color-light-green);
}

.inactive {
	color: var(--color-white);
}

.dropRightSide span {
	display: block;
	width: 100%;
	height: 32px;
	padding-top: 5px;
	text-align: center;
}

.dropTable {
	flex: 1;
	justify-content: space-between;
	overflow-y: scroll;
}

.singleTime {
	width: 100%;
	display: flex;
	justify-content: start;
	cursor: pointer;
	padding: 4px 14px;
}

.singleTime:hover {
	background-color: var(--color-green);
}

.dropTable span {
	display: block;
	width: 49px;
	text-align: left;
}

.selectedTime {
	width: 100%;
	display: flex;
	justify-content: start;
	padding: 4px 14px;
	cursor: pointer;
	color: var(--color-white);
	background-color: var(--color-dark-grey100);
}

.clickedDiv:focus {
	cursor: none;
}
