.title {
	margin-bottom: 12px;
	font: var(--gilroy-Medium-16-20);
	font-weight: 700;
	color: var(--color-dark-grey);
}

.title span {
	font-weight: 400;
}

.dateContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 12px;
	align-items: baseline;
	height: 56px;
}

.datePicker {
	position: relative;
}

.helper {
	position: absolute;
	top: 62px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}
