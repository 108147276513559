.page {
	display: flex;
	padding-top: 48px;
	min-height: 85vh;
}

.main {
	padding: 40px 30px 40px 40px;
	flex-grow: 1;
}

.title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	text-transform: lowercase;
}

.body {
	margin-top: 24px;
}

.bottom_container {
	position: relative;
	width: 100%;
}

.error_container {
	position: relative;
	margin-top: 18px;
}

.error {
	position: absolute;
	right: 30px;
	bottom: 15px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.Helper {
	position: absolute;
	top: 55px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.HelperBtn {
	position: absolute;
	top: 65px;
	left: 137px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.date {
	width: 328px;
}

.Input__radio {
	margin-top: 40px;
	font: var(--gilroy-Medium-16-150);
	letter-spacing: 0.15px;
}

.Input__title {
	font: var(--gilroy-Medium-20);
	color: var(--color-dark-grey);
}

.input {
	position: relative;
	margin-top: 15px;
}

.RadioGroup_date {
	display: grid;
	grid-column-gap: 24px;
	padding: 9px 0;
}

.RadioGroup_date > div > div > div {
	margin: 0;
	font: var(--gilroy-Medium-16-140);
}

.RadioGroup {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 24px;
	padding: 9px 0;
}

.RadioGroup > div > div {
	margin: 0;
}

.Outlets {
	margin-top: 20px;
}

.outlets__title {
	font: var(--gilroy-Medium-16-150);
	letter-spacing: 0.15px;
	color: var(--color-dark-grey);
	margin-left: 3px;
	margin-bottom: 20px;
}

.table {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 40px;
	font: var(--gilroy-Medium-16-20);
	color: var(--color-dark-grey);
}

.table__item {
	display: flex;
	align-items: center;
	padding: 10px 0;
	cursor: pointer;
}

.table__item img {
	padding: 3px;
}

.table__item span {
	margin-left: 12px;
}

.table__item_locked {
	composes: table__item;
	cursor: auto;
}

.button__wrapper {
	margin-top: 48px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	margin-left: 137px;
	width: 328px;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.helper {
	position: relative;
	width: 368px;
	padding: 40px;
	background: var(--color-light-green);
	min-height: calc(100vh - 152px);
}

.helper__wrapper {
	position: sticky;
	top: 112px;
}

.helper__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.helper__main {
	font: var(--gilroy-Medium-16-140);
}

.loader {
	margin-top: 20px;
}

.info button {
	display: flex;
	width: 24px;
}
