.component {
	position: relative;
}

.component > div {
	max-width: 100%;
	margin-bottom: 0 !important;
}

.list {
	position: absolute;
	z-index: 99999;
	top: -18px;
	width: 100%;
	min-height: 50px;
	max-height: 250px;
	margin: 0;
	background-color: var(--color-white);
	border: 2px solid var(--color-light-grey);
	border-top: none;
	overflow: auto;
}

.list li {
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	padding: 0 20px;
	cursor: pointer;
	background-color: var(--color-white);
	font: var(--gilroy-Medium-16-24);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.list li:hover {
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.dropdown {
	position: relative;
}

.notFoundItem {
	justify-content: center;
}

.notFoundItem:hover {
	cursor: default;
	color: var(--color-dark-grey) !important;
	background-color: var(--color-light-grey) !important;
}

.skeleton {
	margin-top: -25px;
}

.icon {
	position: absolute;
	top: 12px;
	right: 0;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
}
