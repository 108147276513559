.playlistListItem {
	display: grid;
	grid-template-columns: 8fr 2fr 2fr 2fr ;
	align-items: center;
	gap: 12px;
	border-bottom: 1px solid var(--color-light-grey);
}

.spotify {
  grid-template-columns: 8fr 2fr 2fr 2fr;
}

.apple {
 grid-template-columns: 8fr 2fr 2fr;
}

.playlistInfoWrapper {
	display: flex;
	align-items: center;
	overflow: hidden;
}

.playlistNumber {
	padding: 16px 0;
	min-width: 38px;
	font: var(--gilroy-Medium-16-19);
	color: var(--color-grey);
	text-align: center;
}

.playlistImg {
	width: 40px;
	height: 40px;
	margin: 0 8px;
}

.playlistName {
	font: var(--gilroy-Medium-16-100);
  line-height: 2;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-right: 20px;
}

.playlistSongName {
	font: var(--gilroy-Medium-12-12);
	color: var(--color-grey);
}

.playlistSongName:hover {
	text-decoration: underline;
	text-underline-offset: 3px;
	color: var(--color-dark-grey);
}

.auditionsQuantity,
.subscribersQuantity,
.platformName,
.emptyPlaylistTitle {
	font: var(--gilroy-Medium-16-24);
}

.linkToPlatform {
	padding-right: 20px;
	justify-self: end;
}

.platformInfo {
	display: flex;
	align-items: center;
	gap: 8px;
}

.platformIcon {
	width: 20px;
	height: 20px;
}

.headerInfoAdaptive {
	display: none;
}

.playlistListItem__Adaptive {
	display: none;
}

@media screen and (max-width: 768px) {
	.header {
		padding: 50px 0 20px 12px;
		border-bottom: 1px solid var(--color-light-grey);
	}
	.headerInfo {
		display: none;
	}
	.playlistListItem {
		display: none;
	}

	.playlistListItem__Adaptive {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(5, 1fr);
		gap: 8px;
		align-items: center;
		border-bottom: 1px solid var(--color-light-grey);
	}

	.playlistInfoWrapper__Adaptive {
		grid-column: span 2 / span 2;
		display: flex;
		align-items: center;
	}

	.headerInfoStreams,
	.headerInfoFollowers,
	.headerInfoPlatform,
	.headerInfoLinkEn,
	.headerInfoLink {
		padding: 0 0 0 10px;
		font: var(--gilroy-Medium-14);
		color: var(--color-grey);
	}

	.headerInfoStreams {
		grid-row-start: 2;
	}

	.headerInfoFollowers {
		grid-column-start: 1;
		grid-row-start: 3;
	}

	.headerInfoPlatform {
		grid-column-start: 1;
		grid-row-start: 4;
	}

	.headerInfoLinkEn,
	.headerInfoLink {
		grid-column-start: 1;
		grid-row-start: 5;
		justify-self: start;
	}

	.auditionsQuantity {
		grid-column-start: 2;
		grid-row-start: 2;
	}

	.subscribersQuantity {
		grid-column-start: 2;
		grid-row-start: 3;
	}

	.platformInfo {
		grid-column-start: 2;
		grid-row-start: 4;
	}

	.linkToPlatform {
		grid-row-start: 5;
		justify-self: start;
	}
}
