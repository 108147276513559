.Dropdown {
	position: relative;
	width: 100%;
}

.List {
	position: absolute;
	z-index: 1;
	top: 46px;
	left: 0;
	overflow: auto;
	overflow-x: hidden;
	width: 100%;
	max-height: 220px;
	margin: 0;
}

.Item {
	font: var(--gilroy-Medium-18);
	display: flex;
	align-items: center;
	width: 100%;
	height: 42px;
	padding: 0 8px;
	cursor: pointer;
	background-color: var(--color-white);
}

.ItemCountry {
	composes: Item;
}

.ItemCountry *:nth-child(2) {
	width: 46px;
}

/* .Item:global.selected {
	color: var(--color-white);
	background-color: var(--color-green);
} */

.Item:hover,
.ItemCountry:hover {
	background-color: var(--color-light-grey);
}

.Input {
	position: relative;
}

.InputElement,
.CountryInputElement {
	height: 42px;
	max-width: 100%;
	margin-bottom: 0;
}

.CountryInputElement input {
	padding-left: 12px;
}

.Value *,
.Item * {
	margin-right: 8px;
}

.Value {
	display: flex;
	border: none;
	border-bottom: 2px solid var(--color-grey);
	background-color: var(--color-white100);
	composes: Item;
	padding-right: 4px;
}

.Helper {
	font: var(--gilroy-Medium-12-12);
	padding: 6px 4px 0px;
}

.Error {
	font: var(--gilroy-Medium-12-12);
}

.Error .Helper {
	position: absolute;
	color: var(--color-red);
}

.Error .Value {
	color: var(--color-red);
	border-color: var(--color-red);
	border-bottom-color: var(--color-red) !important;
}

.Placeholder {
	color: var(--color-grey);
	composes: Value;
}

.Chevron {
	position: absolute;
	right: 18px;
	right: 4px;
	bottom: 14%;
	cursor: pointer;
}

.Error .Input input {
	color: var(--color-red);
	border-color: var(--color-red);
}

.Error .Chevron {
	color: var(--color-red);
}

.Filled .Value {
	border-color: var(--color-dark-grey);
}

.ChevronTriangle {
	position: absolute;
	top: 2px;
	right: 0;
}

.triangle {
	margin: 10px 7px 9px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent rgba(0, 0, 0, 0.54) transparent;
	transition: 0.2s;
}

.triangleDown {
	transform: rotate(180deg);
}

.ItemsToShow {
	display: flex;
	position: relative;
	width: 100%;
	height: 32px;
	padding: 2px 0;
	composes: Item;
}

.ItemsToShow li:not(:last-child) {
	margin-right: 10px;
}

.ItemsToShow li {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 0 4px 12px;
	background: rgba(33, 33, 33, 0.08);
	font: var(--gilroy-Medium-14-20);
	letter-spacing: 0.25px;
}

.ItemsToShow li span {
	margin: 0;
}

.ItemsToShow li img {
	width: 15px;
	margin: 0 9.5px;
	background: rgba(33, 33, 33, 0.08);
	pointer-events: none;
}

.floatingLabel {
	position: absolute;
	top: -14px;
	font: var(--gilroy-Medium-14);
	color: var(--color-grey100);
}
