/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 18:59
 */
.Header {
	position: fixed;
	z-index: 1290;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	width: 100%;
	height: 64px;
	color: var(--color-white);
	border-bottom: 1px solid var(--color-dark-grey100);
	background-color: var(--color-dark-grey100);
	font: var(--gilroy-Medium-18);
}

.Title {
	display: none !important;
	letter-spacing: 1px;
	margin-left: 30px;
	margin-right: auto;
}

.Header__mainButtonsWrapper {
	display: none;
}

.Header__mainButtonsWrapperAdmin {
	display: flex;
	align-items: center;
	height: 100%;
}

.Header__mainButtonsPhoneWrapper {
	display: flex;
}

.Header__mainButtonsPhoneWrapper button {
	margin: 0;
	margin-right: 14px;
}

.Header__mainButton {
	background-color: transparent;
	border: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

.RightMargin {
	margin-right: 24px;
}

.Header__imgWrapper {
	width: 24px;
	height: 40px;
	background: var(--color-white);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.activeLink .Header__imgWrapper {
	background: var(--color-light-grey);
}

.bell__wrapper {
	position: relative;
	display: flex;
}

.bell__dot {
	position: absolute;
	top: -2px;
	right: -2px;
}

.Lang {
	margin-right: 40px;
	grid-area: lang;
}

.Tray {
	display: flex;
	margin: 0;
}

.Tray > * + * {
	margin-left: 10px;
}

.User {
	display: none;
	align-items: center;
	height: 100%;
	padding: 0 30px 0 10px;
	grid-area: user;
	justify-self: end;
	cursor: pointer;
}

.User:global.menu-open {
	background-color: var(--color-dark-grey);
}

.User i {
	font-size: 14px;
	margin-left: 10px;
}

.User:global.menu-open i {
	color: var(--color-white);
}

.UserPhone {
	display: flex;
	align-items: center;
	min-width: auto;
	padding: 0 0 0 40px;
	cursor: pointer;
}

.UserPhone:global.menu-open {
	background-color: var(--color-dark-grey);
}

.UserPhone i {
	font-size: 14px;
	margin-left: 10px;
}

.UserPhone:global.menu-open i {
	color: var(--color-white);
}

.Back {
	opacity: 0;
	visibility: hidden;
	margin-right: auto;
	margin-left: 30px;
}

.Back svg {
	margin-right: 8px;
}

.Back a,
.Back button {
	font: var(--gilroy-Medium-18);
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	color: var(--color-dark-grey);
	border: none;
	outline: none;
	background-color: transparent;
}

.langTray_wrapper_desktop {
	display: none;
}

.langTray_wrapper_mobile {
	display: block;
	margin-left: auto;
}

.langSelectPhoneMode_wrapper {
	z-index: 2;
	position: fixed;
	display: flex;
	align-items: center;
	top: 64px;
	width: 100%;
	height: 64px;
	padding: 0 16px;
	text-align: right;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-light-grey);
}

.langSelectPhoneMode_wrapper.menu__open {
	background-color: var(--color-dark-grey);
}

.menu__open .Header__imgWrapper {
	background-color: var(--color-dark-grey);
}

.menu__open .Header__saveOpen > :first-child {
	fill: var(--color-grey100);
}

.menu__open .Header__saveOpen > :last-child {
	stroke: var(--color-grey100);
}

.menu__open .Header__bellOpen > * {
	stroke: var(--color-grey100);
}

.menu__open .Lang {
	color: var(--color-grey100);
}

.langSelectPhone_ul {
	display: flex;
	margin-left: auto;
}

.langSelectPhone_ul .Lang {
	margin: 0;
}

.langSelectPhone_ul_login {
	display: flex;
	margin-left: auto;
	margin-right: 16px;
}

.langSelectPhone_ul_login .Lang {
	margin: 0;
}

.menuTray_wrapper {
	display: flex;
	justify-content: center;
	letter-spacing: 1px;
	padding-right: 16px;
	cursor: pointer;
}

.menuTray_burger {
	width: 34px;
	height: 14px;
	margin-top: 3px;
	margin-left: 10px;
	background-image: url('../../images/Burger.svg');
	background-position: center;
	background-repeat: no-repeat;
}

.Image {
	margin-bottom: 7px;
	margin-left: 16px;
	width: 93px;
	height: 28px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.Image_dgtal {
	margin-left: 16px;
	width: 93px;
	height: 28px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.ListTitle {
	display: none;
}

@media screen and (min-width: 320px) {
	.menuTray_wrapper {
		margin-left: auto;
	}
}

@media screen and (min-width: 768px) {
	.Header {
		font: var(--gilroy-Medium-24);
		line-height: 28px;
		letter-spacing: 0.96px;
	}

	.Back {
		opacity: 1;
		visibility: visible;
	}

	.langSelectPhoneMode_wrapper {
		display: none;
	}

	.langTray_wrapper_desktop {
		display: block;
	}

	.langTray_wrapper_mobile {
		display: block;
	}

	.menuTray_wrapper {
		display: none;
	}

	.User {
		display: flex;
	}

	.Title {
		display: inline-block !important;
	}
}

@media screen and (min-width: 1024px) {
	.ListTitle {
		display: block;
		margin-right: auto;
		margin-left: 30px;
	}

	.Header {
		position: unset;
		justify-content: flex-end;
		height: 100%;
		color: var(--color-dark-grey200);
		background-color: var(--color-white);
		border-bottom: 1px solid var(--color-light-grey);
	}

	.Header__mainButtonsWrapper {
		display: flex;
		align-items: center;
		margin-right: 30px;
	}
	.Image {
		display: none;
	}
	.Image_dgtal {
		display: none;
	}
}

@media screen and (min-width: 3840px) {
	.Header {
		padding: 30px 60px;
	}
}
