.loader {
	margin-top: 20px;
}

.wizard {
	position: fixed;
	top: 64px;
	visibility: hidden;
	padding: 0 40px;
	width: 100%;
	height: 48px;
	background: var(--color-white);
	z-index: 2;
	border-radius: 2px;
}

.wizard * {
	font: var(--gilroy-Bold-16-19);
	letter-spacing: 1px;
}

.wizard > div {
	margin: 0;
	padding: 0;
	display: inline-block;
	width: auto;
}

:global(.MuiPaper-root.MuiPopover-paper) {
	width: 0;
	max-height: 300px;
}

@media screen and (min-width: 768px) {
	.wizard {
		visibility: visible;
	}
}

@media screen and (min-width: 1024px) {
	.wizard {
		width: calc(100vw - 162px);
	}
}
