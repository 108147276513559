.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'container';
	grid-template-rows: 60px auto;
	grid-template-columns: 100%;
}

.Wizard {
	grid-area: wiz;
}

.Container {
	overflow: hidden;
	padding-top: 50px;
	padding-bottom: 50px;
	grid-area: container;
}

.Section {
	margin-top: 40px;
}

.HeaderLine {
	margin-bottom: 32px;
}

.Header {
	margin-bottom: 34px;
}

.Checkbox {
	margin: 0;
}

.Input {
	width: 100%;
	max-width: unset;
	margin: 0;
}

.FormLine {
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
}

.FormLine > div {
	width: 100%;
	margin: 0;
}

.FormLine > div + div {
	margin-left: 40px;
}

.FormLine > button {
	margin-left: 20px;
}

.Controls {
	margin-top: 50px;
}

.infoButton {
	align-items: flex-start;
}
.infoButton button {
	position: relative;
	top: 17px;
}

.labelWrapper {
	display: flex;
	justify-content: space-between;
	padding-right: 50px;
}

.labelWrapper > button {
	display: flex;
	height: 20px;
	border: none;
	background-color: unset;
	cursor: pointer;
	padding-top: 20px;
}

.editText {
	display: block;
	margin-left: 8px;
	padding-top: -12px;
	font: var(--gilroy-Medium-18);
}

.CountryInput input,
.CountryInput {
	max-width: 416px;
}

.CountryInput {
	margin-bottom: 43px;
}
