.helper {
	position: relative;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}
.helper img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 8px;
	right: 8px;
}

.error {
	position: relative;
	color: var(--color-red100);
	padding: 0px 5px;
}

.errorText {
	position: absolute;
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.loader {
	margin-top: 30px;
}
/* svg */

.statistic__songItemLogo {
	position: absolute;
	top: 16px;
	left: 16px;

	width: 22px;
	height: 22px;
}

/* error */

.statistic__songItemError {
	color: var(--color-red);
}

.statistic__songItemError .statistic__songItemLogo path {
	fill: var(--color-red);
}

.statisticByTerritories__mainContent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* empty */

.statisticByDate__mainContentEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 215px;
}

.inputsWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 8px;
}

.inputsWrapper > div {
	display: flex;
}

.inputTrack {
	width: 100%;
	margin: auto 12px 0px !important;
	/* opacity: 0.2;
	pointer-events: none; */
}

.inputOutlets {
	width: 100%;
	margin-top: auto;
	position: relative;
}

.inputDate {
	min-width: 150px;
	max-width: 160px;
	/* opacity: 0.2;
	pointer-events: none; */
}
.inputStartDate {
	composes: inputDate;
	margin: 0 12px 0 0;
}

.topTracksTable {
	width: 100%;
	border-spacing: 0px 2px;
}

.topTracksTable__body {
	background-color: white;
}

.topTracksTable__header > tr > td {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	/* padding-top: 0;
	padding-bottom: 0; */
	padding: 10px;
}

.topTracksTable__body > tr > td {
	font: var(--gilroy-Medium-16-19);
	padding: 15px 10px;
}

.topTracksTable__body > tr > *:first-child {
	width: 40 px;
}

.topTracksTable__item {
	white-space: nowrap; /* Текст не переносится  */
	overflow: hidden; /* Обрезаем всё за пределами блока  */
	text-overflow: ellipsis; /* Добавляем многоточие */
	position: relative;
}

.topTracksTable__fullName {
	position: absolute;
	left: 0px;
	top: 30px;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	display: none;

	z-index: 1;
	transition: 0.1s ease;
}
.topTracksTable__itemLink:hover .topTracksTable__fullName {
	border: 5px red solid;
	color: red;
	background-color: aquamarine;
	opacity: 1;
	display: block;
	/* visibility: visible; */
}

.topTracksTable__body > tr > *:first-child {
	width: 40px;
}
.topTracksTable__body > tr > *:nth-child(2) {
	max-width: 25vw;
}
.topTracksTable__body > tr > *:nth-child(3) {
	max-width: 20vw;
}
.topTracksTable__body > tr > *:nth-child(4) {
	max-width: 28vw;
}

.topTracksTable__body > tr > *:nth-child(6) {
	max-width: 8vw;
}

.topTracksTitle {
	margin: 40px 0 14px 0;
	font: var(--gilroy-Medium-24-140p);
}

.tableRow:hover {
	cursor: pointer;
}

.statistic__noData {
	margin: 150px 0px;
	text-align: center;
	font: var(--gilroy-Medium-24);
	color: var(---color-dark-grey100);
	text-align: center;
}

.statistics_bar-chart {
	z-index: 10;
	background: var(--color-white);
	padding: 24px;
}

.statistics_bar-chart__controls {
	display: flex;
	justify-content: space-between;
	margin: 16px 0 0;
}

.statistics_bar-chart__controls > ul {
	margin-top: 0;
	width: unset;
}

.statistics_bar-chart__controls-pages {
	margin: 0 0 0 auto;
	display: flex;
	white-space: nowrap;
	align-items: center;
	gap: 8px;
}

.statistics_bar-chart__controls-pages > div {
	margin-top: -24px !important;
	min-width: 80px;
}

.statistics_filter__item {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	text-decoration: underline;
	font: var(--gilroy-Medium-18);
}

.statistics_filter__item + .statistics_filter__item {
	margin: 0 0 0 16px;
}

.statistic__mainContentHeaderInfo {
	margin: 0 0 16px;
}

.statistic__mainContentHeaderInfo_title {
	font: var(--gilroy-Medium-24-140p);
}

.statistic__mainContentHeaderInfo_amount {
	font: var(--gilroy-Medium-16-20);
	color: var(--color-grey);
}

.wrapperDatesBlock {
 width: 100%;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}
