.Menu {
	position: fixed;
	z-index: 4444;
	top: 117px;
	right: 0;
	width: 100%;
	padding: 0;
	background-color: var(--color-dark-grey);
	max-height: calc(100vh - 80px);
	overflow-y: scroll;
}

.Menu ul {
	overflow: auto;
	max-height: 280px;
	margin: 0;
}

.Item {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font: var(--gilroy-Medium-20-28);
	width: 100%;
	color: var(--color-white100);
}

.item_wrapper {
	display: flex;
	align-items: center;
	padding: 8px 16px;
}

.item_wrapper svg {
	width: 18px;
	margin-right: 8px;
}

.Item a {
	font: inherit;
	text-decoration: none;
	color: inherit;
}

.Item:hover {
	text-decoration: line-through;
}

.Item :global a.active {
	text-decoration: line-through;
}

.Item_Active {
	padding: 8px 16px;
	text-decoration: line-through;
	composes: Item;
}

.separator {
	height: 1px;
	margin: 0px 16px 0;
	border-bottom: 1px solid var(--color-grey);
}

.InputWrapper {
	padding: 10px 16px 0px;
}

.notFound {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 85%;
	font: var(--gilroy-Medium-24);
	color: var(--color-white);
}

.flexContainer {
	display: flex;
	align-items: center;
}

.preLoader {
	margin: 81px auto;
	width: 30px;
	height: 30px;
	animation: loader 1s linear infinite;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
		transform-origin: center;
	}
}

@media screen and (min-width: 768px) {
	.Menu {
		position: absolute;
		top: 63px;
		width: 432px;
	}
}
