.Page {
	height: 100%;
	padding: 30px;
	background-color: var(--color-white100);
}

.Banner {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 136px;
	padding: 24px 74px;
	background-color: var(--color-light-green);
}

.Banner :global .header {
	margin-bottom: 8px;
}

.Banner :global .body {
	max-width: calc(100% - 234px);
}

.Banner :global .upload-btn {
	width: 214px;
}

.CloseButton {
	position: absolute;
	top: 10px;
	right: 10px;
}

.File {
	font: var(--gilroy-Medium-18-140);
	display: flex;
	align-items: center;
	width: fit-content;
	max-width: 80%;
	padding: 15px 0;
}

.File i {
	font-size: 24px;
	margin-right: 10px;
}

.File a {
	display: flex;
	align-items: flex-start;
	color: var(--color-dark-grey);
}

.File a img {
	margin-right: 16px;
}

.File:hover span {
	text-decoration: line-through;
}

.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'container';
	grid-template-rows: 60px auto;
	grid-template-columns: 100%;
}

.Wizard {
	grid-area: wiz;
}

.Container {
	grid-area: container;
}

.Right {
	padding: 40px 30px;
	background-color: var(--color-light-green);
}

.Right label {
	font: var(--gilroy-Medium-24);
}

.Right > div {
	padding-left: 70px;
}

.Right ul {
	margin-left: 32px;
}

.Right li + li {
	margin-top: 16px;
}

.Left {
	overflow: auto;
}

.Form {
	min-height: 100%;
	padding: 40px 30px;
}

.Header {
	margin-bottom: 32px;
	font-weight: 700 !important;
}

.FormLine {
	margin-top: 24px;
}

.Subheader {
	margin-bottom: 40px;
}

.Controls {
	margin-top: 80px;
	max-width: 416px;
}

.Controls button {
	text-transform: lowercase;
}

.Radio {
	margin-top: 32px;
	margin-bottom: 24px;
}

.triangle {
	position: absolute;
	bottom: 25px;
	left: 200px;
}

.svg_branding {
	fill: var(--color-light-green);
}

.filesBlock {
	position: relative;
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.FormLine section {
	max-width: 100% !important;
	background-color: inherit !important;
}

.helper {
	position: absolute;
	top: -15px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.error {
	margin-top: 6px;
	display: block;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	font-size: smaller;
	padding: 0 4px;
}


.typesTitle {
	margin-bottom: 15px;
}

.radio {
	margin-bottom: 31px;
}

.radioGroup {
	padding-top: 0 !important;
}

.radioGroup label {
	font: var(--gilroy-Medium-18);
}

.filesTitle {
	margin-bottom: 24px;
}

.textBlock {
	margin-top: 24px;
}

@media screen and (min-width: 768px) {
	.triangle {
		position: fixed;
		bottom: 15px;
		left: 15px;
	}
}

@media screen and (min-width: 1024px) {
	.triangle {
		bottom: 20px;
		left: 180px;
	}
}

@media screen and (min-width: 1440px) {
	.triangle {
		bottom: 20px;
		left: 190px;
	}
}
